<template>
    <nav class="navbar navbar-expand-lg" style="border-bottom: 1px solid grey">
        <div class="container-fluid">
            <div>
                <div class="navbar-brand p-0 m-0" style="cursor: pointer">
                    <img class="logo" src="/images/snqtb.png" style="width:60px" alt="logo" @click="restartHandler">
                </div>
            </div>
            <div class="navbar-brand p-0 m-0 m-lg-3" style="color:#203262; font-weight: bold">
                Portal SNQTB - AFP
            </div>

            <div class="d-flex flex-row-reverse" style="width:60px">
                <ul class="navbar-nav" v-if="state.showMenuItems">
                    <li class="nav-item dropdown">
                        <a class="nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <font-awesome-icon icon="fa-solid fa-user" size="2xl"/>
                        </a>
                        <ul class="dropdown-menu" style="left: -120px; padding: 0; position:absolute">
                            <li>
                                <router-link :to="{name: 'userArea'}"
                                             class="dropdown-item">
                                    <font-awesome-icon :icon="['fas', 'user']"/>
                                    Dados Utilizador
                                </router-link>
                            </li>

                            <li><a class="dropdown-item" @click.prevent="logoutHandler">
                                <font-awesome-icon icon="fa-solid fa-right-from-bracket"/>
                                Logout</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

    <nav class="navbar navbar-expand-lg navbar-dark bg-primary p-2" v-if="state.showMenuItems">
        <ul class="navbar-nav mr-auto">

            <li class="nav-item">
                <router-link :to="{name: 'documentMain'}"
                             active-class="active"
                             class="nav-link"
                             aria-current="page">
                    Documentos
                </router-link>
            </li>
            <li class="nav-item">
                <router-link :to="{name: 'detRecMain'}"
                             active-class="active"
                             class="nav-link"
                             aria-current="page">
                    DET/REC
                </router-link>
            </li>
            <li class="nav-item">
                <router-link :to="{name: 'notePdfMain'}"
                             active-class="active"
                             class="nav-link"
                             aria-current="page">
                    Note PDF
                </router-link>
            </li>
<!--            <li class="nav-item">
                <router-link :to="{name: 'documentPendingMain'}"
                             active-class="active"
                             class="nav-link"
                             aria-current="page">
                    Erros por identificar
                </router-link>
            </li>-->
            <li  v-if="state.isUserAdmin" class="nav-item">
                <router-link :to="{name: 'usersList'}"
                             active-class="active"
                             class="nav-link"
                             aria-current="page">
                    Utilizadores
                </router-link>
            </li>
        </ul>
    </nav>
</template>

<script lang="ts">
/**
 *
 * Created by Filipe - 24/09/2022
 *
 */
import {computed, reactive} from "vue";
import {useStore} from "@/store";
import axios from "axios";
import {useRoute, useRouter} from "vue-router";
import 'bootstrap/dist/js/bootstrap.bundle.min';

export default {
    name: "NavbarMenu",

    setup() {

        const store = useStore();
        const route = useRoute();
        const router = useRouter();

        const state = reactive({
            isUserAdmin: computed<boolean>(() => store.getters.getLoggedUser === null ? false : store.getters.getLoggedUser.profile.description === 'admin'),
            showMenuItems: computed<boolean>(() => route.name !== 'login'),
        })

        const logoutHandler = () => {
            axios.post('/logout')
            window.sessionStorage.clear();
            window.location.assign('/login')
        }

        const restartHandler = () => {
            window.location.assign('/');
        }
        return {
            route,
            router,
            state,
            logoutHandler,
            restartHandler
        }
    }
}
</script>

<style scoped>

</style>
