<template>

    <div class="modal"
         data-bs-backdrop="static"
         id="my_modal">
        <div class="modal-dialog" role="document">
            <div>
                <font-awesome-icon icon="spinner" size="5x" spin :style="{ color: '#dcdcdc' }"></font-awesome-icon>
            </div>
        </div>
    </div>

</template>

<script lang="ts">
import {computed, defineComponent, onMounted, watch} from 'vue'
import {useStore} from "@/store";

import Modal from 'bootstrap/js/dist/modal'

export default defineComponent({
    name: "MaskModal",

    setup() {
        const store = useStore()

        let myModal: Modal;

        onMounted(() => {
            myModal = new Modal('#my_modal', {
                keyboard: false
            })
        })

        const show = computed(() => store.getters.getShowMask)
        watch(show, (show) => {
            show ? myModal.show() : myModal.hide()
        })

        return {
            show
        }

    }
})
</script>

<style scoped>

.modal-dialog div {
    width: 1px;
    top: 35%;
    left: 50%;
    position: fixed;
}

</style>
