<template>

    <div class="alert-main-container">
        <transition-group name="slide-up" tag="div">
            <div class="alert  "
                 :class="{'alert-success' : alert.type === 'success', 'alert-danger' : alert.type === 'error', 'alert-warning' : alert.type === 'warning', 'alert-info' : alert.type === 'info'}"
                 role="alert"
                 :key="alert.id"
                 v-for="alert in messagesList">
                <h4 class="alert-heading text-left">
                    {{ alert.title }}
                </h4>
                <p class="text-left" v-html="alert.message"></p>
                <hr v-show="!!alert.helpText">
                <p class="text-left mb-0" v-show="!!alert.helpText" v-html="alert.helpText"></p>
            </div>
        </transition-group>
    </div>


</template>

<script lang="ts">
import {computed, defineComponent, reactive} from 'vue';
import {useStore} from "@/store";

/*
ALERT TYPES:
- success
- error
- warning
- info

- SHOULD PROVIDE DATA:
{
  title: 'Title',
  message: 'Message',
  type: <ALERT TYPE>,
  helpText: (optional),
}


 */
export default defineComponent({
    name: "AlertPopup",

    setup() {
        // console.log('alert popup context: %o', ctx)
        const store = useStore()
        const messagesList = computed(() => store.getters.getAlertsList)

        const state = reactive({
            title: '',
            message: '',
            helpText: ''
        })

        return {
            state,
            messagesList
        }
    }
})
</script>

<style scoped>

.alert-main-container {
    z-index: 1056;
    width: 380px;
    position: fixed;
    top: 40px;
    right: 50px;
}

.slide-up-enter-active,
.slide-up-leave-active {
    transition: all 200ms ease;
}

.slide-up-move {
    transition: all 200ms ease 50ms;
}

.slide-up-enter {
    opacity: 0;
    transform: translateY(40px);
}

.slide-up-leave-to {
    opacity: 0;
    transform: translateY(-40px);
}

</style>
