import {DocumentsFilterData} from "@/utils/common";
import {Page} from "@/interfaces/common";
import {DocumentDetail} from "@/interfaces/documents";

interface State {
    filterData: DocumentsFilterData | null,
    documentsPage: Page | null

    documentDetail: DocumentDetail | null
}

const state: State = {
    filterData: null,
    documentsPage: null,
    documentDetail: null,
}

export default {
    state,
    getters: {
        getDocumentsFilter: (state: State): DocumentsFilterData | null => state.filterData,
        getDocumentsPage: (state: State): Page | null => state.documentsPage,
        getDocumentDetail: (state: State): DocumentDetail | null => state.documentDetail,
    },
    mutations: {
        setDocumentsFilter(state: State, data: DocumentsFilterData) {
            state.filterData = data;
        },
        setDocumentsPage(state: State, data: Page) {
            state.documentsPage = data;
        },

        setDocumentDetail(state: State, data: DocumentDetail) {
            state.documentDetail = data;
        },
    },
    actions: {},
    namespaced: true,
}
