<template>
    <NavbarMenu/>
    <router-view/>

    <!-- UI utils -->
    <MaskModal/>
    <AlertPopup/>
    <DialogModal/>
    <AckPopup/>

</template>

<script setup lang="ts">
import NavbarMenu from "@/components/NavbarMenu.vue";
import MaskModal from "@/components/MaskModal.vue";
import AlertPopup from "@/components/AlertPopup.vue";
import DialogModal from "@/components/DialogModal.vue";
import AckPopup from "@/components/AckPopup.vue";
import {useStore} from "@/store";
import {onMounted} from "vue";

// eslint-disable-next-line
const components = {NavbarMenu, AlertPopup, MaskModal, DialogModal, AckPopup}


const store = useStore();
onMounted(() => {
    store.dispatch('loadWhoAmI')
})

</script>

<style>
</style>
